body {
    margin: 0px;
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.css-13cymwt-control:focus {
        outline: gold
}

/* icon styling */
.ammoicon {
    display: flex;
    position: relative;
    align-self: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.ammoiconsmall {
    display: flex;
    position: relative;
    align-self: center;
    min-height: 88px;
    height: 100%;
    max-height: 100px;
    margin-left: auto;
    margin-right: auto;
}


/* button styling */
.backbtn {
    float: left;
    margin-left: 10px;
}
button {
    float: right;
    border-radius: 5px;
    background-color: #D49F00;
    color: white;
}
.cartbtndiv {
    position: static;
    display: flex;
    float: right;
    bottom: 0px;
    width: fit-content;
    margin-top: 3px;
}
.cartbtndivsmall {
    display: flex;
    position: absolute;
    margin-top: 12px;
    right: 20px;
}
/* container styling */
.tooSmall {
    height: 50vh;
    width: 100vw;
    position: sticky;
    overflow: hidden;
    z-index: -1;
    text-align: center;
}
.chapselecttitle {
    font-size: 22px;
    transform: translateY(10px);
}
.quickbox {
    overflow-y: hidden;
    position: absolute;
    top: 61vh;
    width: 55vw;
    height: 17vh;
}
.quickboxsmall {
    overflow-y: auto;
    width: 100%;
    bottom: 9vh;
    height: min-content;
}
.quickboxbig{
    overflow-y: auto;
    position: absolute;
    top: 61vh;
    width: 66vw;
    height: 17vh;
}
.quickLinksHeader {
    padding-bottom: 10px;
}
.quickLinksHeaderbig{
    font-size: 55px;
    padding-bottom: 15px;
}
.chaptertag {
    font-weight: bolder;
    width: 100%;
}
/* .branchcontainer {
    height: 79.5vh;
} */
.btndiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    width: 87px;
}
.flexbox {
    display: inline-flex;
    flex-wrap: nowrap;
    column-gap: 49px;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    width: 80vw;
    height: 16.5vh;
}
.flexboxsmall {
    display: inline-flex;
    margin-bottom: 5px;
    flex-wrap: wrap;
    column-gap: 5px;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    width: 99vw;
    height: min-content;
}
.branchname{
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    cursor: pointer;
}
.dropdown {
    max-width: 18vw;
    width: 18vw;
    max-height: min-content;
    box-sizing: border-box;
}
.dropdownsmall {
    max-width: 98vw;
    width: 98vw;
    max-height: min-content;
    box-sizing: border-box;
}
.maincontent{
    position: absolute;
    left: 20vw;
    height: 79.5vh;
    width: 80vw;
}
.maincontentsmall{
    position: absolute;
    overflow-y: auto;
    left: 1vw;
    height: 77%;
    width: 99vw;
}
.maincontentsmalltall{
    position: absolute;
    overflow-y: auto;
    left: 1vw;
    height: calc(100vh - 200px);
    width: 99vw;
}
.maincontentsmallshort{
    position: absolute;
    overflow-y: auto;
    left: 1vw;
    /* height: 77vh; */
    height: calc(91vh - 88px);
    width: 99vw;
}
.selectedChap {
    position: absolute;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
/* .iterator5 {
    flex-wrap: wrap;
    position: relative;
    border-radius: 16px;    
    background-color: #bababa;
    width: 35px;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin-top: 6px;
}
.iterator1 {
    aspect-ratio: 1/1;
    display: flex;
    flex-wrap: wrap;
    border-radius: 50%;    
    background-color: #bababa;
    width: 18px;
    align-items: center;
    cursor: pointer;
    margin-top: 6px;
} */
.iteratorLabelbig{
    font-size: 50px;
}
.calccontainer {
    position: absolute;
    left: 28vw;
    width: 25vw;
    top: 10vh;
    height: 51vh;
    max-height: 51vh;
} 
.calccontainerbig {
    position: absolute;
    left: 28vw;
    width: 26.042vw;
    top: 10vh;
    height: 51vh;
    max-height: 51vh;
    font-size: 48px;
} 
.calccontainersmall {
    width: 98vw;
    height: min-content;
    margin-top: 5vh;
}
.dropcontainer {
    position: absolute;
    left: 0.5vw;
    width: 18vw;
    top: 10vh;
    height: 40vh;
}
.dropcontainersmall {
    width: 98vw;
    height: min-content;
}
.mobilecart {
    width: 98vw;
    height: min-content;
    margin-top: 5vh;
}
.resultcontainer {
    background-color: #f5f5f5;
    width: 100%;
    height: 51vh;
    overflow: hidden;
    height: 42.5vh;
    border-radius: 5px;
}
.resultcontainerbig {
    background-color: #f5f5f5;
    width: 100%;
    height: 51vh;
    overflow: hidden;
    height: 42.5vh;
    border-radius: 5px;
}
.resultinnercontainer {
    background-color: #f5f5f5;
    width: 100%;
    height: 42.5vh;
    max-height: 51vh;
    overflow-y: auto;
}
.resultinnercontainerbig {
    background-color: #f5f5f5;
    width: 100%;
    height: 42.5vh;
    max-height: 51vh;
    overflow-y: auto;
}
.calcbtncontainer {
    width: 100%;
    height: 3.5vh;
    margin-top: 1vh;
}
.calcbtncontainerbig {
    width: 100%;
    height: 7.5vh;
    margin-top: 1vh;
}
.chapselecttitlebig {
    font-size: 2.604vw;
    transform: translateY(10px);
}
.selectionbig {
    font-size: 56px;
}
.selection{
    font-size: 24px;
    font-weight: 500;
}
.notebig {
    font-size: 40px;
}
.note {
    font-size: 16px;
}
.chaptertagbig {
    font-weight: bolder;
    width: 100%;
    font-size: 1.5vw;
}
.btndivbig{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    width: 6.61vw;
    /* max-width: 150px;
    min-width: 150px; */
}
.flexboxbig {
    display: inline-flex;
    flex-wrap: nowrap;
    column-gap: 3vw;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    width: 80vw;
    height: 17.5vh;
}
.branchnamebig{
    /* changed font size added overflow and min-max */
    font-size: 32px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    cursor: pointer;
    overflow-x: auto;
    /* max-width: 150px;
    min-width: 150px; */
}
.dropdownbig {
    max-width: 18vw;
    width: 18vw;
    max-height: min-content;
    box-sizing: border-box;
}
.dropcontainerbig {
    position: absolute;
    left: 0.5vw;
    width: 18vw;
    top: 10vh;
    height: 40vh;
    background-color: #D49F00;
}
/* .iterator{
    display: flex;
    flex-direction: column;
    align-items: right;
} */