.description {
    width: 18vw;
    height: 81vh;
    background-color: #bababa;
    text-align: center;
}
.descriptionInnerBox {
    width: 95%;
    height: 90%;
    max-height: 90%;
    transform: translate(3%, 1%);
    overflow-y: auto;
}
.descriptionButtonDiv {
    display: flex;
    transform: translate(-2px, 52%);
    bottom: 12px;
}
.descriptionbig {
    width: 18vw;
    height: 81vh;
    font-size: 44px;
    background-color: #bababa;
    text-align: center;
}
.descriptionInnerBoxbig {
    width: 95%;
    height: 90%;
    max-height: 90%;
    transform: translate(3%, 1%);
    overflow-y: auto;
    font-size: 90%;
}
.descriptionButtonDivbig {
    display: flex;
    transform: translate(-2px, 52%);
    height: 3vh;
    width: 11vw;
    font-size: 11vw;
}