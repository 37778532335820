.iterator5big{
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 45px;    
    background-color: #bababa;
    width: 2.344vw;
    height: 2.315vh;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin-top: 0.178vh;
    font-size: 48px;
    padding-top: 0.278vh;
    padding-right: 0.781vw;
    padding-bottom: 0.37vh;
    
}
.iterator1big{
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 50%;    
    background-color: #bababa;
    width: 1.712vw;
    height: 2.735vh;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin-top: 0.278vh;
    font-size: 32px;
}
/* Get to work, galen */
.iterator5 {
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 16px;    
    background-color: #bababa;
    width: 35px;
    align-items: center;
    text-align: center;
    cursor: pointer;
    text-align: center;
    padding-left: 6px;
    margin-top: 6px;
}
.iterator1 {
    display: flex;
    flex-direction: row;
    aspect-ratio: 1/1;
    border-radius: 50%;    
    background-color: #bababa;
    width: 18px;
    align-items: center;
    cursor: pointer;
    margin-top: 6px;
    text-align: center
}
.valuebig {
    font-size: 48px;
}
.minusIconbig {
    font-size: 48px;
    display: flex;
    margin: auto;
    width: fit-content;
    align-items: center;
    cursor: pointer;
}
.addIconbig {
    font-size: 48px;
    display: flex;
    margin: auto;
    width: fit-content;
    align-items: center;
    cursor: pointer;
}