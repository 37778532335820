.header {
    height: 10vh;
    background-color: black;
    position: relative;
}
.headersmall {
    min-height: 86px;
    height: 10vh;
    max-height: 100px;
    background-color: black;
    position: relative;
}
.versionbig {
    font-size: 1.25vw;
}