.mobileInfoDrawerContainer {
    display:flex;
    flex-direction: row;
    height: 100vh;
    background-color: none;
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    z-index: -2;
    top: 0vh;
    left: 0vw;
}
.mobileInfoDrawerDescriptionContainer{
    background-color: #bababa;
}
.mobileInfoDrawerContainer {
    display:flex;
    flex-direction: row;
    height: 100vh;
    /* background-color: none; */
    /* border-radius: 0px 8px 8px 0px; */
    position: absolute;
    /* z-index: -2; */
    top: 0vh;
    left: 0vw;
    text-align: center;
}
.mobileInfoDrawerButton {
    position: sticky;
    height:fit-content;
    width: fit-content;
    /* overflow-y: hidden; */
    border-radius: 0px 5px 5px 0px;
    border: none;
    background-color: #D49F00;
    color: white;
    top: 11vh;
    overflow: hidden;
    font-size: 115%;
}
.mobileInfoDrawerDescriptionContainer{
    background-color: #bababa;
    font-size: 115%;
}
.descriptionHeader {
    /* position: absolute; */
    display: flex;
    margin-top: 0px;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 150%;
    font-weight: bold;
}
