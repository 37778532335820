.cartbox {
    right: 0;
    top: 10vh;
    width: 25%;
    height: 81vh;
    background-color: #bababa;
    position: absolute;
}
.innerCartBox {
    background-color: #bababa;
    width: 95%;
    height: 98%;
    max-height: 98%;
    transform: translate(3%, 1%);
    overflow-y: auto;
}
.innerCartBoxbig {
    background-color: #bababa;
    width: 95%;
    height: 98%;
    max-height: 98%;
    font-size: 55px;
    transform: translate(3%, 1%);
    overflow-y: auto;
}
.cartbtndivbig {
    position: static;
    display: flex;
    float: right;
    height: fit-content;
    width: fit-content;
    padding-top: 0.15vw;
}