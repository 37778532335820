.footer {
    height: 9vh;
    background-color: black;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.footerimage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}
.footerimagesmall {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100px;
    position: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}
.footersmall {
    height: 9vh;
    max-height: 100px;
    background-color: black;
    position: sticky;
    margin-top: 81vh;
    width: 100%;
}
.footersmalltall {
    height: 9vh;
    max-height: 100px;
    background-color: black;
    position: sticky;
    margin-top: calc(100vh - 200px);
    width: 100%;
}
.footersmallshort {
    height: 9vh;
    max-height: 100px;
    background-color: black;
    position: sticky;
    margin-top: calc(91vh - 86px);
    width: 100%;
}
